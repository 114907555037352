// config.js

const bizIcon = "https://ckrondonpainting.com/images/ckr-logo.jpg";
const imageOne = "https://ckrondonpainting.com/images/gallery/o%20(38).jpg";
const imageTwo = "https://ckrondonpainting.com/images/gallery/o%20(5).jpg";
const imageThree = "https://ckrondonpainting.com/images/gallery/o%20(6).jpg";
const imageFour = "https://ckrondonpainting.com/images/gallery/o%20(31).jpg";
const imageFive = "https://ckrondonpainting.com/images/service-image3.png";

export const siteConfig = {
  title: "CK Rondon Painting, LLC",
  metaDescription: "CK Rondon Painting, LLC",
  theme: {
    primaryColor: "red-600",
    secondaryColor: "gray-900",
    accentColor: "text-red-500"
  },
  logo: {
    src: bizIcon,
    alt: "CK Rondon Painting, LLC Business",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "CK Rondon Painting LLC",
    headline: "Premium Painting Supplies and Services",
    address: "19886 Antler Trl, Abbeville, AL 35111",
    description: "Quality paints, tools, and expert advice for pros."
  },
  images: {
    business: {
      src: imageOne,
      alt: "Premium Painting Supplies and Services",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
      name: 'Professional-Grade Paint and Supplies',
      description: 'Stocked with top-quality paints, primers, and tools, ideal for achieving flawless results in every project.',
    },
    {
      name: 'Custom Color Matching',
      description: 'Precise color matching services to help you find or replicate any color for a perfect finish every time.',
    },
    {
      name: 'Residential and Commercial Expertise',
      description: 'Skilled in both residential and commercial projects, offering tailored solutions for homes and businesses alike.',
    }
  ],
  services: {
    sectionTitle: "Our Painting & Surface Care Services",
    description: "At CK Rondon Painting, LLC, we deliver high-quality painting and surface care services tailored for both residential and commercial spaces. With over 10 years of experience, we focus on exceptional workmanship, whether refreshing interiors, updating exteriors, or adding specialty finishes. Explore our full list of services and see how we bring your vision to life.",
    callouts: [
      {
        name: 'Interior & ExteriorPainting',
        description: 'Transform rooms with vibrant, professional finishes.',
        details: 'From single rooms to full interior makeovers, our skilled team uses high-quality paints to give your space a fresh, modern look.',
        imageSrc: imageTwo,
        imageAlt: 'Modern interior with fresh paint',
      },
      {
        name: 'Cabinet Painting & Staining',
        description: 'Refinish cabinets for an updated, stylish look.',
        details: 'We offer cabinet painting and staining to modernize kitchens, bathrooms, or office spaces, providing custom color matching and high-quality finishes.',
        imageSrc: imageThree,
        imageAlt: 'Stylish painted kitchen cabinets',
      },
      {
        name: 'Pressure Washing',
        description: 'Deep clean outdoor surfaces for a refreshed look.',
        details: 'Our high-pressure washing removes dirt, grime, and mildew, restoring driveways, decks, and other surfaces to their original beauty.',
        imageSrc: imageFour,
        imageAlt: 'Driveway being pressure washed',
      }
    ],
    otherServices: [
      "Ceiling Painting",
      "Deck & Patio Painting",
      "Paint Removal",
      "Concrete Floor Coating",
      "Specialty Texture Painting",
      "Metal Roof Painting",
      "Cabinet Refinishing",
      "Power Washing",
      "Wallpaper Removal",
      "Trim and Baseboard Painting"
    ]
  },  
  about: {
    sectionTitle: "About Us",
    description: "Welcome to CK Rondon Painting, LLC, your trusted painting and surface care specialists in McCalla, Alabama. With over a decade of industry experience, we are committed to delivering quality painting solutions for residential and commercial clients alike. Accredited by the Better Business Bureau, we are dedicated to quality, reliability, and 100% customer satisfaction.",
    image: {
      src: imageFive,
      alt: "CK Rondon Painting team in action",
      width: 2432,
      height: 1442
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "McCalla, AL 35111, USA",
    googleMapsEmbedUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3347.306193214849!2d-87.07927568481964!3d33.30455998080745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x888925012e7c2295%3A0x660660b7a7f6e3af!2sMcCalla%2C%20AL%2035111%2C%20USA!5e0!3m2!1sen!2sph!4v1710407450828!5m2!1sen!2sph",
    subCategories: [
      { name: 'Apple Maps', url: 'https://maps.apple.com/?address=McCalla,%20AL%2035111&ll=33.3045,-87.0792&q=McCalla,%20AL%2035111' },
      { name: 'Bing Maps', url: 'https://www.bing.com/maps?q=McCalla%2C+AL+35111&form=HDRSC6&sp=1&pq=mccalla+al+35111&sc=0-0&qs=n&sk=&cvid=EDFC0FE5A0E74E8DB130342A842AA10C' },
      { name: 'Google Earth', url: 'https://earth.google.com/web/search/McCalla,+AL+35111,+USA/@33.3045599,-87.0792756,15.26523397a,814.57589625d,35y,0h,0t,0r' },
      { name: 'Google Maps', url: 'https://maps.app.goo.gl/4Y2YfHGWxgrC8tTE7' }
    ]
  },  
  contact: {
    sectionTitle: "Ready to Transform Your Space?",
    description: "Contact us to discuss your painting needs or to schedule a consultation. We’re here to help bring your vision to life.",
    manager: {
      name: "Carlo Rondon",
      title: "Operations Manager",
      email: "carlorondonpainting@gmail.com",
    },
    socialLinks: {
      facebook: "https://www.facebook.com/manolitorondon",
      instagram: "https://www.facebook.com/manolitorondon",
      twitter: "https://www.facebook.com/manolitorondon",
      youtube: "https://www.facebook.com/manolitorondon",
      tiktok: "https://www.facebook.com/manolitorondon"
    },
    contactNumbers: ["(205) 650-5139"],
    logo: bizIcon,
    emailRecipient: "miguelrondonperez@gmail.com"
  },
  footer: {
    bizSolutionsLogo: "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg"
  }
};
